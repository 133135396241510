

.contenedor {
  padding-right: 0;
  padding-top: 0;
  padding-left: 0px;
  /* margin-top: 10; */
  margin-left: 240px;
  transition: margin-left 0.5s ease-in;
  /* box-sizing: border-box; */
}


  .contenedor.inactive {
    /* padding-top: 10; */
    margin-left: 0px;/*ORIGINAL VALUE WAS 60px*/
    padding-right: 0;
    padding-left: 0px;/*ORIGINAL VALUE WAS 12px*/
  }

