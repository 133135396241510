.BottomTabs--MainContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 2; /* Add this line */
}

.MuiBottomNavigationAction-root {
  min-width: 0;
  padding: 6px 12px 8px;
}

.MuiBottomNavigationAction-label {
  font-size: 0.75rem;
}

.Mui-selected {
  color: #1976d2; /* Or your preferred highlight color */
}
/*RESPONSIVENESS STARTS HERE ;)*/
/*THESE ARE GENERAL SIZES*/
/*------------------------------------------------------------------*/
/*X-Small                                                           */
/*------------------------------------------------------------------*/
/*Portrait*/
@media only screen and (max-width: 575px) and (orientation: portrait) {
  /*PORTRAIT*/
  .BottomTabs--MainContainer {
    display: flex;
  }
}
@media only screen and (max-height: 575px) and (orientation: landscape) {
  /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Small                                                             */
/*------------------------------------------------------------------*/
/*Portrait*/
@media only screen and (min-width: 576px) and (max-width: 767px) and (orientation: portrait) {
  /*PORTRAIT*/
  .BottomTabs--MainContainer {
    display: flex;
  }
}
/*LANDSCAPE*/
@media only screen and (min-height: 576px) and (max-height: 767px) and (orientation: landscape) {
  /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Medium                                                            */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 768px) and (max-width: 991px) and (orientation: portrait) {
  /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-height: 768px) and (max-height: 991px) and (orientation: landscape) {
  /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Large                                                             */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 992px) and (max-width: 1199px) and (orientation: portrait) {
  /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-height: 992px) and (max-height: 1199px) and (orientation: landscape) {
  /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Extra large                                                       */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 1200px) and (max-width: 1399px) and (orientation: portrait) {
  /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-height: 1200px) and (max-height: 1399px) and (orientation: landscape) {
  /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Extra extra large                                                      */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 1400px) and (max-width: 5000px) and (orientation: portrait) {
  /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-height: 1400px) and (max-height: 5000px) and (orientation: landscape) {
  /*LANDSCAPE*/
}
