.linkref:hoover {
  display: block;
  width: 100px;
  height: 25px;
  background: #0cceff;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  color: white;
}

.forgot {
  text-align: right;
  color: rgb(54, 9, 253);
  margin-left: 150px;
  text-decoration: none;
}

.signup {
  width: 80px;
  height: 30px;
  float: right;
  background-color: white;
  color: black;
  border: 1px solid rgb(104, 170, 240);
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  margin-bottom: 30px; /* margin-left: 250px;*/
}

h3 {
  margin: 0;
  display: inline-block;
}

.view-password-btn {
  top: -50px;

  width: 40px;
  height: 35px;
  position: relative;

  background: transparent;
  border: 0;
  font-size: 20px;
  color: #666;
}

.card-content {
  padding: 12px 44px;
}
#card-title {
  font-family: 'Raleway Thin', sans-serif;
  letter-spacing: 4px;
  padding-bottom: 23px;
  padding-top: 13px;
  text-align: center;
}
.card-title {
  font-family: 'Raleway Thin', sans-serif;
  letter-spacing: 4px;
  padding-bottom: 23px;
  padding-top: 13px;
  text-align: center;
}

.login-btn {
  width: 50px;
}

.underline-title {
  background: -webkit-linear-gradient(right, #bfbfbf, #b9b9b9);
  height: 2px;
  margin: -1.1rem auto 0 auto;
  width: 89px;
}

#card {
  background: #fbfbfb;
  border-radius: 7px;
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.65);
  height: 410px;
  margin: 6rem auto 8.1rem auto;
  width: 329px;
}

.form-content {
  margin-bottom: 1rem;
  display: block;

  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s;
}

.form-border {
  background: -webkit-linear-gradient(right, #ababab, #838383);
  height: 1px;
  width: 100%;
  margin-bottom: 1px;
}

#submit-btn {
  background: -webkit-linear-gradient(left, #827d7d, #000000);
  border: none;
  border-radius: 7px;
  box-shadow: 0px 1px 8px #686a69;
  cursor: pointer;
  color: white;
  font-family: 'Raleway SemiBold', sans-serif;
  height: 42.3px;
  margin: 0 auto;
  margin-top: 50px;
  transition: 0.25s;
  width: 153px;
}
