:root{
    --timeLineBackgroundColor:#015b9b;
    --timeLineBorderRadius:10px;
    --timeLineCircleDiameter:12px;
    --timeLineCircleBorderRadius:20px;
    --timeLineCircleMarginTop:10px;
}
.TimeLineCard--MainContainer{
    display: flex;
    height: fit-content;
}
.TimeLineCard--MainContainer:hover{
    opacity: 0.8;
}
.TimeLineCard-LeftContanier{
    display: flex;
    justify-content: center;
    width: 20px;
    overflow: visible;
    padding-left: 3px;
}
.firstItem{
    overflow: visible;
    background-color: var(--timeLineBackgroundColor);
    border-top-left-radius:var(--timeLineBorderRadius) ;
    border-top-right-radius:var(--timeLineBorderRadius) ;
    display: flex;
    justify-content: center;
    width: 20px;
}
.intermediateItem{
    overflow: visible;
    background-color: var(--timeLineBackgroundColor);
    display: flex;
    justify-content: center;
    width: 20px;
}
.lastItem{
    overflow: visible;
    background-color: var(--timeLineBackgroundColor);
    border-bottom-left-radius:var(--timeLineBorderRadius) ;
    border-bottom-right-radius:var(--timeLineBorderRadius) ;
    display: flex;
    justify-content: center;
    width: 20px;
}
.selected{
    width: var(--timeLineCircleDiameter);
    height: var(--timeLineCircleDiameter);
    border-radius: var(--timeLineCircleBorderRadius);
    margin-top: var(--timeLineCircleMarginTop);
    background-color: rgb(255, 255, 255);
    opacity: 0.7;
    transition: opacity ease-in 0.2s;
}
.notSelected{
    width: var(--timeLineCircleDiameter);
    height: var(--timeLineCircleDiameter);
    border-radius: var(--timeLineCircleBorderRadius);
    margin-top: var(--timeLineCircleMarginTop);
    background-color: rgb(255, 255, 255);
    opacity: 0.3;
    transition: opacity ease-in 0.2s;
}
.TimeLineCard-CenterContanier{
    display: flex;
    flex-direction: column;
    padding-top: 5px;
    padding-bottom: 10px;
    padding-left: 10px;
}
.TimeLineCard-RightContanier{
    display: flex;
    padding-top: 5px;
}