/* @media screen and (max-width: 601px) {
  .track-grid-container {
    display: flex;
    flex-direction: column;
  }

  #map {
    order: 1;
  }
  #list {
    order: 2;
  }
  .map {
    overflow: visible;
    height: 30vh;
    width: 100%;
  }
  .map2 {
    width: 100%;
    height: 100%;
  }
  .list-wrap {
    margin-top: 1rem;
    margin-left: 1rem;
  }

  .track-list {
    height: 70vh;
  }
} */

@media screen and (min-width: 601px) {
  .map2 {
    width: 100%;
    height: 100%;
  }
  /* THIS CONTROLS THE WIDTH OF  THE LEFT MENU THAT CONTAINS VEHICLES AND DRIVERS CONTROLLERS */
  .track-grid-container {
    display: grid;
    grid-template-columns: auto 0.99fr;
    grid-template-rows: 80vh;
    grid-template-areas:
      'map'
      'list-wrap';

    transition: 0.5s;
  }

  /* .track-grid-container.inactive {
    grid-template-columns: 0vh auto;
    transition: 1s;
  } */

  .list-wrap {
    overflow: 'auto';
    grid-area: 'list-wrap';
    display: flex;
  }
  .map {
    grid-area: 'map';
    position: relative;
    position: 'relative';
    width: '100%';
    overflow: 'hide';
  }
}

.track-list {
  margin-top: 1rem;
  padding: 0;
  margin-right: 0;
  overflow: scroll;
  overflow-y: scroll;
  overflow-x: hidden;

  width: 90%;
}
.track-list-item:focus {
  background-color: rgb(222, 242, 242);
}

.track-list-item:active {
  background-color: rgb(222, 242, 242);
}

.track-list-item:hover {
  background-color: rgb(222, 242, 242);
}
.track-list-divider {
  margin-left: 0;
  margin-top: 0px;
  width: 100%;
  height: 1px;
  border-radius: 1px;
  background: rgb(202, 195, 195);
}

.vehicle-info {
  appearance: none;
  background: rgb(255, 255, 255);
  border-radius: 0.25rem;
  border-style: none;
  box-shadow: rgba(0, 0, 0, 0.2) 0 3px 5px -1px;
  box-sizing: border-box;
  width: auto;
}

.vehicle-info :active {
  appearance: none;
  background: rgb(226, 192, 192);
  border-radius: 0.25rem;
  border-style: none;
  box-shadow: rgba(0, 0, 0, 0.2) 0 3px 5px -1px;
  box-sizing: border-box;
  width: auto;
}

.vehicle-info :hover {
  background: rgb(142, 75, 75);
}

.hide-button {
  margin-left: 250px;
  stroke-width: 1;

  border-color: black;
}

.hide-button.inactive {
  margin-left: 00px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 19px;
  width: 20px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  /* background-color: #2196f3; */
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
